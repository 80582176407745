<template>
    <v-card class="mt-4 mx-auto" max-width="640" min-width="550">
        <v-sheet
            class="v-sheet--offset mx-auto"
            color="rgb(66, 179, 244)"
            elevation="12"
            max-width="calc(100% - 32px)"
        >
            <v-sparkline :labels="labels" :value="value" color="white" line-width="2" padding="16"></v-sparkline>
        </v-sheet>

        <v-card-text class="pt-0">
            <div class="title font-weight-light mb-2">
                <br />Stromkosten letzte {{numberOfDays+1}} Tag | max | avg | Vorschau Monat
                <v-row align="center">
                    <v-col cols="3">
                        <v-icon>mdi-power-socket-eu</v-icon>
                        {{ precipAmount | number-format(1) }} €
                    </v-col>
                    <v-col cols="3">
                        <v-icon>mdi-arrow-up-bold</v-icon>
                        {{ max | number-format(2) }} €/d
                    </v-col>
                    <v-col cols="3">
                        <v-icon>mdi-arrow-right-bold</v-icon>
                        {{ avg | number-format(2) }} €/m
                    </v-col>

                    <v-col cols="3">
                        <v-icon>mdi-calendar-month</v-icon>
                        {{ (avg*30) | number-format(2) }} €
                    </v-col>
                </v-row>
            </div>
        </v-card-text>
    </v-card>
</template>
<script>
export default {
    name: "SmartMeterHistory",
    components: {},
    data: () => ({
        labels: [],
        value: [],
        precipAmount: 0,
        max: 0,
        sum: 0,
        avg: 0,
        numberOfDays: 14
    }),
    mounted() {
        this.$global_axios
            .get(`/api/v1/smartmeter/aggregation/${this.numberOfDays}`)
            .then(res => {
                const data = res.data;
                data.pop();
                for (var current_id in data) {
                    var current_data = data[current_id];
                    this.labels.push(current_data.id.substring(8));
                    this.value.push(current_data.priceOfTheDay);
                    this.sum += current_data.priceOfTheDay;
                    if (this.max == 0 || this.max < current_data.priceOfTheDay) {
                        this.max = current_data.priceOfTheDay;
                    }
                    this.precipAmount += current_data.priceOfTheDay;
                }
                this.avg = this.sum / data.length;

            })
            // eslint-disable-next-line
            .catch(error => console.log(error));
    },
    methods: {}
};
</script>
