<template>
    <v-card class="mx-auto" max-width="400" min-width="280">
        <v-list-item two-line>
            <v-list-item-content>
                <v-list-item-title class="headline">{{location}} {{description}}</v-list-item-title>
                <v-list-item-subtitle>
                    <v-icon small>mdi-update</v-icon>
                    {{timeOfRecording | format-last-update }}
                </v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>

        <v-card-text>
            <v-row align="center">
                <v-col class="display-3" cols="6">{{temp | number-format(1) }}&deg;</v-col>
                <v-col cols="6">
                    <img :src="logo" width="100" />
                </v-col>
            </v-row>
        </v-card-text>

        <v-list-item>
            <v-row align="center">
                <v-col cols="4">
                    <v-icon>mdi-arrow-down</v-icon>
                    {{tempMin | number-format(1) }}&deg;
                </v-col>
                <v-col cols="4">
                    <v-icon>mdi-arrow-up</v-icon>
                    {{tempMax | number-format(1) }}&deg;
                </v-col>
                <v-col cols="4">
                    <v-icon>mdi-weather-rainy</v-icon>
                    &nbsp;{{precipAmount | number-format(1) }}
                </v-col>
            </v-row>
        </v-list-item>
        <v-list-item>
            <v-row align="center">
                <v-col cols="6">
                    <v-icon>mdi-send</v-icon>
                    {{wind_speed | number-format(1) }} km/h
                </v-col>
                <v-col cols="6">
                    <v-icon>mdi-water-percent</v-icon>
                    {{humidity}}%
                </v-col>
            </v-row>
        </v-list-item>
    </v-card>
</template>
<script>
export default {
    name: "WeatherForecast",
    data() {
        return {
            location: "Focke",
            description: "Garten",
            logo: require("@/assets/img/focke-outside.jpg"),
            temp: 0,
            wind_speed: 0,
            humidity: 0,
            timeOfRecording: "",
            precipAmount: 0,
            tempMax: 0,
            tempMin: 0,
            precipHourly: []
        };
    },
    mounted() {
        this.$global_axios
            .get("/api/v1/weather/station/currentday/focke85_garten")
            .then(res => {
                const data = res.data;
                this.temp = data.temp;
                this.wind_speed = data.windspeedKmph;
                this.humidity = data.humidity;
                this.timeOfRecording = data.timeOfRecording;
                this.precipAmount = data.precipAmount;
                this.tempMax = data.tempMax;
                this.tempMin = data.tempMin;
                this.precipHourly = data.precipHourly;
            })
            // eslint-disable-next-line
            .catch(error => console.log(error));
    }
};
</script>
