<template>
    <v-card class="mx-auto" max-width="400" min-width="280">
        <v-list-item two-line>
            <v-list-item-content>
                <v-list-item-title class="headline">Zählerstand Strom</v-list-item-title>
                <v-list-item-subtitle>
                    <v-icon small>mdi-update</v-icon>
                    {{smartmeter.timeOfRecording | format-last-update }}
                </v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>

        <v-card-text>
            <v-row align="center">
                <v-col class="display-1" cols="12">
                    <v-icon large>mdi-power-plug</v-icon>
                    {{ smartmeter.powerConsumptionCount | number-format(2) }}
                    kWh
                </v-col>
            </v-row>
        </v-card-text>

        <v-list-item>
            <v-list-item-subtitle>Wirkleistung aktuell: {{smartmeter.currentPowerValue | number-format(0) }} W</v-list-item-subtitle>
        </v-list-item>
    </v-card>
</template>

<script>
export default {
    name: "Powermeter",
    components: {},
    data: () => ({
        smartmeter: {
            powerConsumptionCount: 0,
            currentPowerValue: 0,
            timeOfRecording: "2019-11-23T23:09:12.088+00:00"
        },
        time: 0
    }),
    mounted() {
        this.$global_axios
            .get("/api/v1/smartmeter/latest")
            .then(res => {
                const data = res.data;
                this.smartmeter.powerConsumptionCount =
                    data.powerConsumptionCount;
                this.smartmeter.currentPowerValue = data.currentPowerValue;
                this.smartmeter.timeOfRecording = data.timeOfRecording;
            })
            // eslint-disable-next-line
            .catch(error => console.log(error));
    },
    methods: {
        sayHello: function() {
            return "";
        }
    }
};
</script>