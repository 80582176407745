<template>
    <v-card class="mx-auto" max-width="400" min-width="280">
        <v-list-item two-line>
            <v-list-item-content>
                <v-list-item-title class="headline">Vorhersage {{location}}</v-list-item-title>
                <v-list-item-subtitle>
                    <v-icon small>mdi-update</v-icon>
                    {{timeOfRecording | format-last-update }}
                    <br />
                    <span
                        v-for="description in descriptions"
                        v-bind:key="description.id"
                    >{{ description }}&nbsp;</span>
                </v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>

        <v-card-text>
            <v-row align="center">
                <v-col class="display-3" cols="8">{{temperature}}&deg;C</v-col>
                <v-col cols="4">
                    <v-img :src="icon" alt="Sunny image" width="62"></v-img>
                </v-col>
            </v-row>
        </v-card-text>

        <v-list-item>
            <v-row align="center">
                <v-col cols="6">
                    <v-icon>mdi-send</v-icon>
                    {{wind_speed}} km/h
                </v-col>
                <v-col cols="6">
                    <v-icon>mdi-cloud-download</v-icon>
                    {{humidity}}%
                </v-col>
            </v-row>
        </v-list-item>
    </v-card>
</template>
<script>
export default {
    name: "WeatherForecast",
    data() {
        return {
            time: 0,
            temperature: 0,
            wind_speed: 0,
            humidity: 0,
            location: "-",
            timeOfRecording: "",
            icon: "",
            descriptions: ""
        };
    },
    mounted() {
        this.$global_axios
            .get("/api/v1/forecast/data")
            .then(res => {
                const data = res.data;
                this.temperature = data.temperature;
                this.wind_speed = data.wind_speed;
                this.humidity = data.humidity;
                this.location = data.location;
                this.timeOfRecording = data.timeOfRecording;
                this.icon = data.weather_icons[0];
                this.descriptions = data.weather_descriptions;
            })
            // eslint-disable-next-line
            .catch(error => console.log(error));
    }
};
</script>
