<template>
    <v-container>
        <v-row>
            <v-col>
                <weather-widget></weather-widget>
            </v-col>
            <v-col>
                <indoor-widget></indoor-widget>
            </v-col>
            <v-col>
                <power-meter-widget></power-meter-widget>
            </v-col>
            <v-col>
                <kwh-per-hour-line-widget></kwh-per-hour-line-widget>
            </v-col>
            <v-col>
                <precip-line-widget></precip-line-widget>
            </v-col>
            <v-col>
                <weather-forecast-widget></weather-forecast-widget>
            </v-col>
            <v-col>
                <smart-meter-history-widget></smart-meter-history-widget>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Powermeter from "@/components/widgets/Powermeter.vue";
import Precipline from "@/components/widgets/Precipline.vue";
import Kwhperhour from "@/components/widgets/KwhPerHour.vue";
import Weather from "@/components/widgets/Weather.vue";
import Indoor from "@/components/widgets/Indoor.vue";
import WeatherForecast from "@/components/widgets/WeatherForecast.vue";
import SmartMeterHistory from "@/components/widgets/SmartMeterHistory.vue";
export default {
    name: "Dashboard",

    components: {
        "power-meter-widget": Powermeter,
        "precip-line-widget": Precipline,
        "kwh-per-hour-line-widget": Kwhperhour,
        "weather-forecast-widget": WeatherForecast,
        "weather-widget": Weather,
        "indoor-widget": Indoor,
        "smart-meter-history-widget": SmartMeterHistory,
    },

    data: () => ({
        drawer: null
    })
};
</script>
