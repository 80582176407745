<template>
    <v-card class="mt-4 mx-auto" max-width="400" min-width="280">
        <v-sheet
            class="v-sheet--offset mx-auto"
            color="orange"
            elevation="12"
            max-width="calc(100% - 32px)"
        >
            <v-sparkline :labels="labels" :value="value" color="white" line-width="2" padding="16"></v-sparkline>
        </v-sheet>

        <v-card-text class="pt-0">
            <div class="title font-weight-light mb-2">
                <br />Verbrauch in kWh/h
                <br />
                <v-icon>mdi-sigma</v-icon>
                {{ sumOfDay | number-format(1) }} kWh
                <v-icon>mdi-arrow-down-bold</v-icon>
                {{ min | number-format(0) }} 
                <v-icon>mdi-arrow-up-bold</v-icon>
                {{ max | number-format(0) }} W/h
            </div>
        </v-card-text>
    </v-card>
</template>
<script>
export default {
    name: "KwhPerHour",
    components: {},
    data: () => ({
        labels: [],
        value: [],
        sumOfDay: 0,
        min: 0,
        max: 0
    }),
    mounted() {
        this.$global_axios
            .get("/api/v1/smartmeter/aggregation")
            .then(res => {
                const data = res.data;
                for (var current_id in data) {
                    var current_data = data[current_id];
                    var totalHour =
                        current_data.powerConsumptionCount -
                        current_data.powerConsumptionCountStart;
                    this.labels.push(current_data.hourOfDay + 1);
                    this.value.push(totalHour);

                    this.sumOfDay = this.sumOfDay + totalHour;

                    if (
                        this.min == 0 ||
                        this.min > current_data.currentPowerValueAvg
                    ) {
                        this.min = current_data.currentPowerValueAvg;
                    }
                    if (
                        this.max == 0 ||
                        this.max < current_data.currentPowerValueAvg
                    ) {
                        this.max = current_data.currentPowerValueAvg;
                    }
                }
            })
            // eslint-disable-next-line
            .catch(error => console.log(error));
    },
    methods: {}
};
</script>