<template>
    <v-card class="mt-4 mx-auto" max-width="400" min-width="280">
        <v-sheet
            class="v-sheet--offset mx-auto"
            color="rgb(66, 179, 244)"
            elevation="12"
            max-width="calc(100% - 32px)"
        >
            <v-sparkline :labels="labels" :value="value" color="white" line-width="2" padding="16"></v-sparkline>
        </v-sheet>

        <v-card-text class="pt-0">
            <div class="title font-weight-light mb-2">
                <br />Heutige Regenmengen & Verlauf
                <v-row align="center">
                    <v-col cols="6">
                        <v-icon>mdi-weather-rainy</v-icon>
                        {{ precipAmount | number-format(1) }} mm
                    </v-col>
                    <v-col cols="6">
                        <v-icon>mdi-arrow-up-bold</v-icon>
                        {{ max | number-format(2) }} mm/h
                    </v-col>
                </v-row>
            </div>
        </v-card-text>
    </v-card>
</template>
<script>
export default {
    name: "Precipline",
    components: {},
    data: () => ({
        labels: [],
        value: [],
        precipAmount: 0,
        max: 0
    }),
    mounted() {
        this.$global_axios
            .get("/api/v1/weather/station/currentday/focke85_garten")
            .then(res => {
                const data = res.data.precipMmPerHourMap;
                for (var current_id in data) {
                    var current_data = data[current_id];
                    this.labels.push(current_id);
                    this.value.push(current_data);

                    if (this.max == 0 || this.max < current_data) {
                        this.max = current_data;
                    }
                    this.precipAmount += current_data;
                }
            })
            // eslint-disable-next-line
            .catch(error => console.log(error));
    },
    methods: {}
};
</script>
