import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import axios from 'axios'

Vue.config.productionTip = false

Vue.prototype.$global_axios = axios
//axios.defaults.baseURL = 'http://localhost:8080'
//axios.defaults.baseURL =  'https://smart-home-interface.herokuapp.com'
//axios.defaults.baseURL =  'https://weather-station.fly.dev'
axios.defaults.baseURL =  'https://spezialmann.kfz-taeschner.de/'


/**
 * Common functions
 */
Vue.filter('format-last-update', function(value) {
    var ret = value.replace("0000", "00:00");
    try {
        var d = new Date(ret);
        ret = d.toLocaleString("de-DE");
    } catch (error) {
        // eslint-disable-next-line
        console.log("Error: " + error);
    }
    return ret;
});
Vue.filter('number-format', function(value, decimalDigits) {
  return value
    .toFixed(decimalDigits)
    .replace('.', ',')
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
});


new Vue({
  vuetify,
  render: h => h(App)
}).$mount('#app')
